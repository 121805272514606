import { Link, graphql } from 'gatsby';
import React from 'react';

import urns from '../assets/images/cremation-urns.jpg';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StyledButton from '../components/styledButton';

const Testimonial = ({ title, name, children }) => {
  return (
    <div className='w-full sm:w-1/2 -mx-2 flex flex-col items-start'>
      <div className='mx-2'>
        <h2 className='text-3xl font-serif font-hairline italic text-gray-400'>
          "{title}"
        </h2>
        <p className='mt-2'>
          <strong className='mr-2 ml-8 text-lg text-gray-600'>-{name}</strong>
        </p>
      </div>
      <div className='w-full mx-2'>
        <blockquote className='testimonial-quote'>{children}</blockquote>
      </div>
    </div>
  );
};

const Testimonials = ({ data }) => {
  const testimonials = data.allMarkdownRemark.edges;
  return (
    <Layout heroImage={urns}>
      <SEO title='Testimonials | We Help Families' />
      <div className='min-h-screen pt-24 mb-20 flex flex-col items-start'>
        <div className='w-3/4 md:w-1/2 mt-16 font-serif font-hairline'>
          <h1 className='text-4xl md:text-5xl text-green-700'>Testimonials</h1>
        </div>
        <h1 className='py-4'>
          At City of Oaks Cremation we have an all-inclusive, low cost approach
          for cremation, burials, or traditional funerals.
        </h1>
        <p className='py-4'>
          We're family-owned, family-operated, and family-oriented. We promise
          to make this process bearable and affordable and we are committed to
          doing it with the utmost professionalism. The families we serve are
          typically educated, practical, environmentally conscious and
          economically sound decision makers.
          <p className='py-4'>
            Here's what some of those families have had to say about working
            with us:
          </p>
        </p>
        <div className='w-full mt-16 flex flex-row flex-wrap justify-between'>
          {testimonials.map(({ node }) => {
            const { title, name, path } = node.frontmatter;
            const html = node.html;
            return (
              <Testimonial key={path} title={title} name={name}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Testimonial>
            );
          })}
        </div>
        <div className='self-center my-3'>
          <Link to='/contact/'>
            <StyledButton>Talk to us</StyledButton>
          </Link>
        </div>

        <div className='self-center my-3'>
          <Link to='/services/'>
            <StyledButton>Our services</StyledButton>
          </Link>
        </div>
        <Guarantee />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Testimonials {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/testimonials/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            name
          }
          html
        }
      }
    }
  }
`;

export default Testimonials;
